/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { EntityType } from 'embercom/models/data/entity-types';
import {
  BULK_ACTION_PROGRESSION_ID,
  type BulkActionProgressionEvent,
  BulkActionProgressionValues,
  type SyncSourceWrapperResponse,
} from 'embercom/services/knowledge-hub-service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import ENV from 'embercom/config/environment';
import type ContentImportService from 'embercom/services/content-import-service';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';
import { type ViewId } from 'embercom/components/knowledge-hub/setup-modal';

export enum ContentSourceType {
  PublicArticle = 'public_article',
  Website = 'website',
  Snippet = 'snippet',
  PDF = 'pdf',
  ZendeskArticle = 'zendesk_article',
  ConversationExtraction = 'conversation_extraction',
  InboxConversations = 'inbox_conversations',
  CustomAnswer = 'custom-answer',
}

interface Args {
  finIsLive: boolean;
  openSectionId?: string;
  setOpenSectionId: (openSectionId: string) => void;
}

interface Signature {
  Args: Args;
}

export default class Content extends Component<Signature> {
  entityType = EntityType;
  contentSourceType = ContentSourceType;

  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare contentImportService: ContentImportService;
  @service declare conversationExtractionService: ConversationExtractionService;
  @service declare store: Store;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  @tracked sourceTypeToImport?: ViewId;
  @tracked showSetupModal = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.knowledgeHubService.subscribeToSourceSyncEvents();
    if (this.canUseConversationExtractionExperiment) {
      this.conversationExtractionService.subscribeToImportProgressEvents();
      if (args.openSectionId === 'conversation-content') {
        this.showConversationExtractionModal();
        args.setOpenSectionId('');
      }
    } else if (args.openSectionId === 'conversation-content') {
      this.showConversationModal();
      args.setOpenSectionId('');
    }
    this.setBulkActionProgressActions();
  }

  willDestroy(): void {
    super.willDestroy();
    this.destroyNexusEvents();
    this.knowledgeHubService.unsubscribeToSourceSyncEvents();
    if (this.canUseConversationExtractionExperiment) {
      this.conversationExtractionService.unsubscribeFromImportProgressEvents();
    }
  }

  private setBulkActionProgressActions() {
    this.realTimeEventService.on(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  private destroyNexusEvents() {
    this.realTimeEventService.off(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  async handleBulkActionProgression(event: BulkActionProgressionEvent) {
    let currentAdmin = this.appService.app.currentAdmin;
    if (currentAdmin.id !== event.admin_id.toString()) {
      return;
    }

    if (event.status === BulkActionProgressionValues.Complete) {
      if (event.all_entities_unaffected) {
        this.knowledgeHubService.notifyNoEntitiesAffected(event.bulk_action);
      } else {
        this.sendCompletionNotification(event);
        setTimeout(() => {
          this.knowledgeHubService.fetchKnowledgeUsageSummary();
        }, ENV.APP._500MS);
      }
    }

    if (event.status === BulkActionProgressionValues.InvalidFolder) {
      this.notificationsService.notifyError(
        this.intl.t('knowledge-hub.filterable-list.bulk-actions.invalid-folder'),
      );
    }
  }

  private async sendCompletionNotification(event: BulkActionProgressionEvent) {
    let action = event.bulk_action;
    let unaffectedEntityTypes = event.unaffected_entity_types;
    let language_code = event.language_code;
    let destinationFolderId = event.destination_folder_id;
    this.knowledgeHubService.notifyBulkActionCompleted(
      action,
      unaffectedEntityTypes,
      language_code,
      destinationFolderId,
    );
    if (destinationFolderId) {
      await this.knowledgeHubService.fetchFoldersOnce(true);
    }
  }

  get finConversationContentSettings() {
    return this.contentImportService.finConversationContentSettings;
  }

  get conversationExtractionSettings() {
    return this.conversationExtractionService.conversationExtractionSettings;
  }

  get canUseConversationExtractionExperiment() {
    return this.appService.app.canUseConversationExtractionExperiment;
  }

  get showEmptyState() {
    if (this.canUseConversationExtractionExperiment) {
      return this.totalContentCount === 0 && !this.conversationExtractionSettings?.enabled;
    }
    return this.totalContentCount === 0;
  }

  get totalArticlesCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ArticleContent]?.all || 0;
  }

  get enabledArticlesCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ArticleContent]?.agent || 0;
  }

  get totalSnippetsCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ContentSnippet]?.all || 0;
  }

  get enabledSnippetsCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ContentSnippet]?.agent || 0;
  }

  get totalPdfsCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.FileSourceContent]?.all || 0;
  }

  get enabledPdfsCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.FileSourceContent]?.agent || 0;
  }

  get totalCustomAnswersCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.Answer]?.all || 0;
  }

  get enabledCustomAnswersCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.Answer]?.agent || 0;
  }

  get enabledExternalContentCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.agent || 0;
  }

  get totalExternalContentCount() {
    return this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.all || 0;
  }

  get totalEnabledContentCount() {
    return (
      this.enabledArticlesCount +
      this.enabledSnippetsCount +
      this.enabledPdfsCount +
      this.enabledExternalContentCount
    );
  }

  get totalContentCount() {
    return (
      this.totalArticlesCount +
      this.totalSnippetsCount +
      this.totalPdfsCount +
      this.totalExternalContentCount
    );
  }

  get disabledArticlesCriteria() {
    return {
      disableBulkEnable: this.enabledArticlesCount === this.totalArticlesCount,
    };
  }

  @action isExternalContentSyncDisabled(syncSource: SyncSourceWrapper): boolean {
    if (
      syncSource.errorType === 'non_retryable_error' ||
      syncSource.errorType === 'no_content' ||
      syncSource.errorType === 'unauthorized'
    ) {
      return true;
    } else {
      return false;
    }
  }

  get disabledSnippetsCriteria() {
    return {
      disableBulkEnable: this.enabledSnippetsCount === this.totalSnippetsCount,
    };
  }

  get disabledPdfsCriteria() {
    return {
      disableBulkEnable: this.enabledPdfsCount === this.totalPdfsCount,
    };
  }

  get externalContentSyncSources(): SyncSourceWrapperResponse[] {
    return this.knowledgeHubService.usageSummary?.[EntityType.ExternalContent]?.sync_sources ?? [];
  }

  @action getExternalContentSourceWrapper(
    externalContentSource: SyncSourceWrapperResponse,
  ): SyncSourceWrapper {
    let id = `${externalContentSource.source_type}-${externalContentSource.source_id}`;
    return this.store.peekRecord('knowledge-hub/sync-source-wrapper', id);
  }

  @action goToContent(entityType: EntityType, syncSource?: SyncSourceWrapper) {
    if (entityType === EntityType.ExternalContent && syncSource) {
      this.knowledgeHubService.goToSyncSourceFolder(syncSource, {});
    } else {
      this.knowledgeHubService.goToContent(entityType, {});
    }
  }

  get zendeskSyncSource(): SyncSourceWrapperResponse | undefined {
    if (!this.knowledgeHubService.usageSummary) {
      return;
    }

    return this.knowledgeHubService.usageSummary[EntityType.ArticleContent]?.sync_sources.find(
      ({ source_type }: { source_type: string }) => source_type === 'zendesk',
    );
  }

  get zendeskSyncSourceWrapper(): SyncSourceWrapper | undefined {
    if (!this.zendeskSyncSource) {
      return undefined;
    }
    let id = `${this.zendeskSyncSource.source_type}-${this.zendeskSyncSource.source_id}`;
    return this.store.peekRecord('knowledge-hub/sync-source-wrapper', id);
  }

  @action
  async showModal(syncSourceType: string, entityType: EntityType) {
    try {
      await this.knowledgeHubService.ensurePermissions(entityType);
    } catch (error) {
      return;
    }

    this.sourceTypeToImport = syncSourceType as ViewId;
    this.showSetupModal = true;
  }

  @action
  closeSetupModal() {
    this.showSetupModal = false;
    this.knowledgeHubService.onSummaryChange();
    if (this.sourceTypeToImport === 'conversation-extraction') {
      this.conversationExtractionService.maybeRollbackConversationExtractionSettings();
    }
    this.sourceTypeToImport = undefined;
  }

  @action
  async showConversationExtractionModal() {
    // awaiting here to check permissions
    await this.showModal('conversation-extraction', EntityType.ContentSnippet);
  }

  @action
  async showConversationModal() {
    // awaiting here to check permissions
    await this.showModal('past-conversation', EntityType.ConversationExcerpt);
  }

  @action
  goToConversationFolder() {
    this.conversationExtractionService.goToContent(false);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FinAiAgent::Knowledge::Content': typeof Content;
  }
}
